import React from "react"
import { Typography, Grid, Button } from "@material-ui/core"
import { COLOR_BLACK, COLOR_LIGHT, treeGridColors, treeGridColorsMap } from "../constants"
import { Check } from "react-feather"
import { TreeGridColors } from "../treegrid/types"
import { useI18n } from "../hooks"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import { useIsSmallScreen } from "../hooks/use-is-small-screen"

type ColorSelectProps = {
  selectedColor: TreeGridColors
  onColorChange: (color: TreeGridColors) => void
  disabled?: boolean
}

const ColorSelectionWidget: React.FC<ColorSelectProps> = ({ selectedColor, onColorChange, disabled = false }) => {
  const translations = useTranslations()
  const colorOptions = treeGridColors.filter((color) => color !== "Default")

  return (
    <div>
      <Typography component="h4" variant="body1">
        <strong>{translations.color}</strong>
      </Typography>
      <Grid container spacing={1} style={{ marginTop: "8px" }}>
        {colorOptions.map(($color) => {
          const label = `${$color[0]}${$color[1]}`
          const labelToShow = selectedColor === $color ? <Check /> : label
          const color = treeGridColorsMap[$color]
          const textColor = $color === "White" || $color === "Yellow" ? COLOR_BLACK : COLOR_LIGHT
          return (
            <Grid item key={color}>
              <Button
                variant="outlined"
                style={{ backgroundColor: color, color: selectedColor === $color ? textColor : color, padding: "0" }}
                onClick={() => {
                  if ($color === selectedColor) return
                  onColorChange($color)
                }}
                size="small"
                disabled={disabled}
              >
                {labelToShow}
              </Button>
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}

const ColorSelectMobile: React.FC<ColorSelectProps> = ({ selectedColor, onColorChange, disabled = false }) => {
  const translations = useTranslations()
  const colorOptions = treeGridColors.filter((color) => color !== "Default")
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!disabled) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelectColor = (color: TreeGridColors) => {
    onColorChange(color)
    handleClose()
  }

  const selectedColorValue = treeGridColorsMap[selectedColor]
  const checkColor = selectedColor === "White" || selectedColor === "Yellow" ? COLOR_BLACK : COLOR_LIGHT

  return (
    <div>
      <Typography component="h4" variant="body1" gutterBottom>
        <strong>{translations.color}</strong>
      </Typography>
      <Button
        onClick={handleClick}
        variant="outlined"
        disabled={disabled}
        style={{
          padding: 0,
          minHeight: 56,
          position: "relative",
          backgroundColor: `${selectedColorValue}2f`,
          borderColor: `${selectedColorValue}2f`,
        }}
        fullWidth
      >
        <div
          style={{
            backgroundColor: selectedColorValue,
            width: 32,
            height: 32,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: 12,
            left: 12,
            bottom: 12,
            borderRadius: "50%",
          }}
        >
          <Check color={checkColor} size={24} />
        </div>
        <Typography variant="body1" style={{ marginLeft: 20, display: "inline-block", fontWeight: 500 }}>
          {selectedColor}
        </Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {colorOptions.map((color) => {
          const colorValue = treeGridColorsMap[color]
          return (
            <MenuItem key={color} onClick={() => handleSelectColor(color)} style={{ maxWidth: 280 }} divider>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <div
                    style={{
                      width: 24,
                      height: 24,
                      backgroundColor: colorValue,
                      borderRadius: 4,
                      marginRight: 8,
                    }}
                  />
                </Grid>
                <Grid item style={{ flexGrow: 1 }}>
                  <Typography variant="body2" style={{ color: "black" }}>
                    {color}
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>
          )
        })}
      </Menu>
    </div>
  )
}

const useTranslations = (defaults: Translations = defaultTranslations) => {
  const { translations: t } = useI18n("translation")
  const { color = defaults.color } = t
  return { color }
}

const defaultTranslations = {
  color: "Color",
}

type Translations = typeof defaultTranslations

export default function ColorSelection(props: ColorSelectProps) {
  const isSmallScreen = useIsSmallScreen()
  return isSmallScreen ? <ColorSelectMobile {...props} /> : <ColorSelectionWidget {...props} />
}
