import { api } from "../../api"
import { NotificationChannel, NotificationType } from "../../notifications/notifications"
import { makeOrganisationApiEndpoints } from "./endpoints"
import { CheckBillingSettingsViewModel, OrganisationViewModel, makeCheckBillingSettingsViewModel } from "./organisation"
import { CheckBillingSettingsResponse } from "./organisation-response"
import { ProjectTypeViewModel } from "./project-type"
import { SystemStatusId } from "./project-type-response"

const apiEndpoints = makeOrganisationApiEndpoints()

export const organisationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrganisationById: builder.query<OrganisationViewModel, string>({
      query: (id) => apiEndpoints.organisationItem(id),
      providesTags: ["Organisation"],
    }),
    checkBillingSettings: builder.query<CheckBillingSettingsViewModel, string>({
      query: (id) => apiEndpoints.orgBillingSettingsCheck(id),
      transformResponse: (checkBillingSettings: CheckBillingSettingsResponse) => {
        return makeCheckBillingSettingsViewModel(checkBillingSettings)
      },
    }),
    updateOrgNotificationSettings: builder.mutation<
      OrganisationViewModel,
      { orgId: string } & OrgNotificationSettingsUpdateData
    >({
      query: ({ orgId, ...updateData }) => ({
        url: apiEndpoints.orgNotificationSettings(orgId),
        method: "PUT",
        body: updateData,
      }),
      invalidatesTags: ["Organisation"],
    }),
    getProjectTypes: builder.query<ProjectTypeViewModel[], string>({
      query: (id) => apiEndpoints.projectTypes(id),
      providesTags: ["ProjectType"],
    }),
    addProjectType: builder.mutation({
      query: ({ orgId, type }: NewProjectTypeData & { orgId: string }) => ({
        url: apiEndpoints.projectTypes(orgId),
        method: "POST",
        body: { type },
      }),
      invalidatesTags: ["ProjectType"],
    }),
    updateProjectType: builder.mutation({
      query: ({ orgId, id, ...updateData }: ProjectTypeUpdateData & { orgId: string; id: string }) => ({
        url: apiEndpoints.projectTypeItem(id, orgId),
        method: "PUT",
        body: updateData,
      }),
      invalidatesTags: ["ProjectType"],
    }),
    deleteProjectType: builder.mutation({
      query: ({ orgId, id }: { orgId: string; id: string }) => ({
        url: apiEndpoints.projectTypeItem(id, orgId),
        method: "DELETE",
      }),
      invalidatesTags: ["ProjectType"],
    }),
    renameProjectStatus: builder.mutation({
      query: ({
        orgId,
        id,
        projectTypeId,
        ...updateData
      }: ProjectCustomStatusUpdateData & { orgId: string; id: SystemStatusId; projectTypeId: string }) => ({
        url: apiEndpoints.projectStatusItem(id, projectTypeId, orgId),
        method: "PUT",
        body: updateData,
      }),
      invalidatesTags: ["ProjectType"],
    }),
    addProjectSubStatus: builder.mutation({
      query: ({
        projectStatusId,
        projectTypeId,
        orgId,
        ...subStatusData
      }: NewSubStatusData & { projectStatusId: SystemStatusId; projectTypeId: string; orgId: string }) => ({
        url: apiEndpoints.projectSubStatuses(projectStatusId, projectTypeId, orgId),
        method: "POST",
        body: subStatusData,
      }),
      invalidatesTags: ["ProjectType"],
    }),
    updateProjectSubStatus: builder.mutation({
      query: ({
        id,
        orgId,
        projectTypeId,
        projectStatusId,
        ...updateData
      }: SubStatusUpdateData & {
        id: string
        orgId: string
        projectTypeId: string
        projectStatusId: SystemStatusId
      }) => ({
        url: apiEndpoints.projectSubStatusItem(id, projectStatusId, projectTypeId, orgId),
        method: "PUT",
        body: updateData,
      }),
      invalidatesTags: ["ProjectType"],
      async onQueryStarted({ id, orgId, projectTypeId, projectStatusId, name }, { dispatch, queryFulfilled }) {
        if (name) {
          const patchResult = dispatch(
            // @ts-expect-error
            api.util.updateQueryData("getProjectTypes", orgId, (draft: ProjectTypeViewModel[]) => {
              draft.forEach((projectType) => {
                if (projectType.id === projectTypeId) {
                  projectType.projectCustomStatuses[projectStatusId].subStatuses.forEach((subStatus) => {
                    if (subStatus.id === id) {
                      subStatus.name = name
                    }
                  })
                }
              })
            })
          )
          try {
            await queryFulfilled
          } catch {
            patchResult.undo()
          }
        }
      },
    }),
    deleteProjectSubStatus: builder.mutation({
      query: ({
        id,
        orgId,
        projectTypeId,
        projectStatusId,
      }: {
        id: string
        orgId: string
        projectTypeId: string
        projectStatusId: SystemStatusId
      }) => ({
        url: apiEndpoints.projectSubStatusItem(id, projectStatusId, projectTypeId, orgId),
        method: "DELETE",
      }),
      invalidatesTags: ["ProjectType"],
    }),
    renameTaskStatus: builder.mutation({
      query: ({ orgId, id, ...updateData }: TaskCustomStatusUpdateData & { orgId: string; id: SystemStatusId }) => ({
        url: apiEndpoints.taskStatusItem(id, orgId),
        method: "PUT",
        body: updateData,
      }),
      invalidatesTags: ["Organisation"],
    }),
    addTaskSubStatus: builder.mutation({
      query: ({ orgId, id, ...updateData }: NewSubStatusData & { orgId: string; id: SystemStatusId }) => ({
        url: apiEndpoints.taskSubStatuses(id, orgId),
        method: "POST",
        body: updateData,
      }),
      invalidatesTags: ["Organisation"],
    }),
    updateTaskSubStatus: builder.mutation({
      query: ({
        id,
        statusId,
        orgId,
        ...updateData
      }: SubStatusUpdateData & { orgId: string; id: string; statusId: SystemStatusId }) => ({
        url: apiEndpoints.taskSubStatusItem(id, statusId, orgId),
        method: "PUT",
        body: updateData,
      }),
      invalidatesTags: ["Organisation"],
      async onQueryStarted({ id, statusId, orgId, name }, { dispatch, queryFulfilled }) {
        if (name) {
          const patchResult = dispatch(
            // @ts-expect-error
            api.util.updateQueryData("getOrganisationById", orgId, (draft: OrganisationViewModel) => {
              draft.taskStatuses[statusId].subStatuses.forEach((subStatus) => {
                if (subStatus.id === id) {
                  subStatus.name = name
                }
              })
            })
          )
          try {
            await queryFulfilled
          } catch {
            patchResult.undo()
          }
        }
      },
    }),
    deleteTaskSubStatus: builder.mutation({
      query: ({ id, statusId, orgId }: { orgId: string; id: string; statusId: SystemStatusId }) => ({
        url: apiEndpoints.taskSubStatusItem(id, statusId, orgId),
        method: "DELETE",
      }),
      invalidatesTags: ["Organisation"],
    }),
  }),
})

export const {
  useGetOrganisationByIdQuery,
  useCheckBillingSettingsQuery,
  useUpdateOrgNotificationSettingsMutation,
  useGetProjectTypesQuery,
  useAddProjectTypeMutation,
  useUpdateProjectTypeMutation,
  useDeleteProjectTypeMutation,
  useRenameProjectStatusMutation,
  useAddProjectSubStatusMutation,
  useUpdateProjectSubStatusMutation,
  useDeleteProjectSubStatusMutation,
  useRenameTaskStatusMutation,
  useAddTaskSubStatusMutation,
  useUpdateTaskSubStatusMutation,
  useDeleteTaskSubStatusMutation,
} = organisationApi

export type OrgNotificationSettingsUpdateData = {
  enable: NotificationUpdateAction[]
  disable: NotificationUpdateAction[]
}

export type NewProjectTypeData = {
  type: string
}

type NotificationUpdateAction = { type: NotificationType; channel: NotificationChannel }
export type ProjectTypeUpdateData = {
  type?: string
  isVisibleInOptions?: boolean
  isDefault?: boolean
}
export type ProjectCustomStatusUpdateData = {
  name: string
}

export type NewSubStatusData = {
  name: string
}
export type SubStatusUpdateData = {
  name?: string
  order?: number
  isVisibleInOptions?: boolean
}

export type TaskCustomStatusUpdateData = {
  name: string
}
