import AutoSaveInput from "../AutoSaveInput"

import { IContact } from "../../types/contact"
import { useI18n } from "../../hooks"

const ContactInfo = ({
  contact,
  onFirstnameSave,
  onLastnameSave,
  onEmailSave,
  onPhoneSave,
  onJobTitleSave,
  editable = true,
}: ContactInfoProps) => {
  const translations = useTranslations(defaultTranslations)
  return (
    <>
      <AutoSaveInput
        name="firstname"
        label={translations.firstnameLabel}
        placeholder="e.g. Acme Inc"
        onSave={onFirstnameSave}
        initialValue={contact.firstname}
        disabled={!editable}
        inputProps={{ "data-test": "contact-firstname-input" }}
      />
      <AutoSaveInput
        name="lastname"
        label={translations.lastnameLabel}
        placeholder="e.g. Acme Inc"
        onSave={onLastnameSave}
        initialValue={contact.lastname}
        disabled={!editable}
        inputProps={{ "data-test": "contact-lastname-input" }}
      />
      <AutoSaveInput
        name="email"
        label={translations.emailLabel}
        placeholder="e.g. Acme Inc"
        onSave={onEmailSave}
        initialValue={contact.email || ""}
        disabled={!editable}
        inputProps={{ "data-test": "contact-email-input" }}
      />
      <AutoSaveInput
        name="phone"
        label={translations.phoneLabel}
        placeholder="e.g. Acme Inc"
        onSave={onPhoneSave}
        initialValue={contact.phone || ""}
        disabled={!editable}
        inputProps={{ "data-test": "contact-phone-input" }}
      />
      <AutoSaveInput
        name="jobTitle"
        label={translations.jobTitleLabel}
        placeholder="e.g. Acme Inc"
        onSave={onJobTitleSave}
        initialValue={contact.jobTitle || ""}
        disabled={!editable}
        inputProps={{ "data-test": "contact-job-title-input" }}
      />
    </>
  )
}

const useTranslations = (defaults: Translations): Translations => {
  const { translations: t } = useI18n("supplier")
  const translations = (t.contactForm || {}) as { [k: string]: string }

  const {
    firstnameLabel = defaults.firstnameLabel,
    lastnameLabel = defaults.lastnameLabel,
    emailLabel = defaults.emailLabel,
    phoneLabel = defaults.phoneLabel,
    jobTitleLabel = defaults.jobTitleLabel,
  } = translations

  return {
    firstnameLabel,
    lastnameLabel,
    emailLabel,
    phoneLabel,
    jobTitleLabel,
  }
}

const defaultTranslations = {
  firstnameLabel: "Firstname",
  lastnameLabel: "Lastname",
  emailLabel: "Email",
  phoneLabel: "Phone",
  jobTitleLabel: "Job title",
}

type ContactInfoProps = {
  contact: IContact
  onFirstnameSave: (values: { [k in keyof IContact]: string }) => Promise<void>
  onLastnameSave: (values: { [k in keyof IContact]: string }) => Promise<void>
  onEmailSave: (values: { [k in keyof IContact]: string }) => Promise<void>
  onPhoneSave: (values: { [k in keyof IContact]: string }) => Promise<void>
  onJobTitleSave: (values: { [k in keyof IContact]: string }) => Promise<void>
  editable?: boolean
}

type Translations = typeof defaultTranslations

export default ContactInfo
