import { Form, Formik, FormikHelpers } from "formik"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { CreateCustomer } from "../../customers/store/actions"
import { CustomerTypeEnum, OrgCustomerPayload } from "../../types/customer"
import { useI18n, useNetworkStatus } from "../../hooks"
import { makeValidateCustomerNumber } from "../utils/validate-customer-number"
import { Button, CircularProgress, Grid, makeStyles, Theme } from "@material-ui/core"
import { Plus } from "react-feather"
import { paths } from "../../paths"
import InputLabel from "../../components/InputLabel"
import FormikInput from "../../components/FormikInput"
import FormErrorAlert from "../../components/FormErrorAlert"
import { useAppContext } from "../../hooks/use-app-context"
import { useUrlWithContext } from "../../hooks/use-url-with-context"
import { StringMap } from "../../types/common"

const OrganisationForm = () => {
  const classes = useStyles()
  const translations = useTranslations()
  const dispatch = useDispatch()
  const history = useHistory()
  const networkStatus = useNetworkStatus({ resetDelayInMS: 3000 })
  const { appContext } = useAppContext()
  const { createPathWithGivenContext } = useUrlWithContext()
  const orgId = appContext.mainContext?.id as string
  const { setStatus, isIdle, isRejected, isPending } = networkStatus

  const createPayload = (values: OrgCustomerFormProps) => {
    const { name, customerNumber } = values
    const customerType = CustomerTypeEnum.organisation

    let payload = {
      type: customerType,
    } as OrgCustomerPayload
    if (name) payload = { ...payload, name }
    if (customerNumber) payload = { ...payload, customerNumber: Number(customerNumber) }

    return payload
  }

  const handleClickSubmit = async (
    values: OrgCustomerFormProps,
    { setSubmitting }: FormikHelpers<OrgCustomerFormProps>
  ) => {
    try {
      setStatus("pending")
      const payload = createPayload(values)
      const thunkParams = { orgId, ...payload }
      const actionResult: any = await dispatch(CreateCustomer(thunkParams))
      const requestStatus = actionResult.meta.requestStatus
      setStatus(requestStatus)
      setSubmitting(false)
      if (requestStatus === "fulfilled") {
        const customerId = (actionResult.payload as any).id
        const customerHomeURLWithContext = createPathWithGivenContext({
          path: paths.customerSettings(),
          mainContextId: orgId,
          subContextId: customerId,
        })
        history.push(`${customerHomeURLWithContext}&created=now`)
      }
    } catch (error: any) {}
  }

  const formikProps = {
    initialValues: {
      name: "",
      customerNumber: "",
    },
    onSubmit: handleClickSubmit,
    validate: makeValidate(orgId, translations),
  }

  let createButtonStartIcon: null | JSX.Element = null
  let createButtonEndIcon: null | JSX.Element = <Plus size={16} />
  let submitButtonLabel = translations.submitButtonLabel_idle

  if (isPending()) {
    createButtonStartIcon = <CircularProgress size={16} />
    createButtonEndIcon = null
    submitButtonLabel = translations.submitButtonLabel_pending
  }

  return (
    <Formik {...formikProps}>
      {({ errors, values }) => {
        const shouldDisableInput = !isIdle()
        const shouldDisableButton = Boolean(values.name === "" || errors.name || errors.customerNumber || !isIdle())

        return (
          <Form>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <InputLabel label={translations.nameLabel} isRequired={true} />
                <FormikInput
                  name="name"
                  variant="outlined"
                  placeholder="e.g. Starbrix Intl"
                  className={classes.input}
                  disabled={shouldDisableInput}
                  data-test="customer-name-input"
                />
              </Grid>
              <Grid item>
                <InputLabel label={translations.customerNumberLabel} isRequired={true} />
                <FormikInput
                  name="customerNumber"
                  variant="outlined"
                  placeholder="e.g. 123456789"
                  disabled={shouldDisableInput}
                  className={classes.input}
                  data-test="customer-number-input"
                />
              </Grid>
              <Grid item className={classes.errorMessage}>
                {isRejected() && (
                  <FormErrorAlert
                    title={translations.errorAlertTitle}
                    description={translations.errorAlertDescription}
                  />
                )}
                {errors.customerNumber && (
                  <FormErrorAlert title={translations.errorAlertTitle} description={errors.customerNumber} />
                )}
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  startIcon={createButtonStartIcon}
                  endIcon={createButtonEndIcon}
                  disabled={shouldDisableButton}
                  data-test="submit-button"
                >
                  {submitButtonLabel}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n("translation")
  const { translations: customerTranslations } = useI18n("customer")
  const customerForm = (customerTranslations.customerForm || {}) as StringMap
  const translations = t || ({} as StringMap)

  const somethingWentWrongError = translations.appFallbackErrorMessage || defaults.somethingWentWrongError
  const {
    nameLabel = defaults.nameLabel,
    customerNumberLabel = defaults.customerNumberLabel,
    submitButtonLabel_idle = defaults.submitButtonLabel_idle,
    submitButtonLabel_pending = defaults.submitButtonLabel_pending,
    errorAlertTitle = defaults.errorAlertTitle,
    errorAlertDescription = defaults.errorAlertDescription,
    invalidCustomerNumberError = defaults.invalidCustomerNumberError,
    duplicateCustomerNumberError = defaults.duplicateCustomerNumberError,
    numberOnlyCustomerNumberError = defaults.numberOnlyCustomerNumberError,
  } = customerForm

  return {
    nameLabel,
    customerNumberLabel,
    submitButtonLabel_idle,
    submitButtonLabel_pending,
    errorAlertTitle,
    errorAlertDescription,
    invalidCustomerNumberError,
    duplicateCustomerNumberError,
    numberOnlyCustomerNumberError,
    somethingWentWrongError,
  }
}

const defaultTranslations = {
  nameLabel: "Customer name",
  customerNumberLabel: "Customer number",
  submitButtonLabel_idle: "Create customer",
  submitButtonLabel_pending: "Creating customer...",
  errorAlertTitle: "Error",
  errorAlertDescription: "Failed to create customer",
  invalidCustomerNumberError: "Customer number is not valid",
  duplicateCustomerNumberError: "Customer number already exists",
  numberOnlyCustomerNumberError: "Customer number can contain numbers only",
  somethingWentWrongError: "Something went wrong",
}

const useStyles = makeStyles((theme: Theme) => ({
  errorMessage: {
    marginBottom: theme.spacing(2),
  },
  input: {
    width: "100%",
  },
}))

type Translations = typeof defaultTranslations
type OrgCustomerFormProps = {
  name: string
  customerNumber: string
}
type ValidationErrors = { [k in keyof OrgCustomerFormProps]: string }

const makeValidate = (orgId: string, translations: Translations) => {
  const validateCustomerNumber = makeValidateCustomerNumber({ orgId, translations })
  return async (values: OrgCustomerFormProps) => {
    const errors = {} as ValidationErrors
    const { customerNumber } = values

    if (!values.name || typeof values.name !== "string") {
      errors.name = "Name is required"
    }

    /** Uniqueness validation of customer number */
    if (customerNumber) {
      const validationResult = await validateCustomerNumber({ customerNumber })
      if (validationResult.customerNumber) errors.customerNumber = validationResult.customerNumber
    }
    return errors
  }
}

export default OrganisationForm
