import * as userApi from "../users/user-api"
import doublet from "../utils/doublet"
import React from "react"
import Button from "@material-ui/core/Button"
import FormLabel from "@material-ui/core/FormLabel"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import FormikInput from "../components/FormikInput"
import CircularProgress from "@material-ui/core/CircularProgress"
import { Alert, AlertTitle } from "@material-ui/lab"
import { useI18n } from "../hooks"
import { StringMap } from "../types/common"
import { useAuthUser } from "../users/hooks/use-auth-user"
import { makeStyles, Theme } from "@material-ui/core"
import { Formik, Form } from "formik"
import { Link, Redirect, useLocation } from "react-router-dom"
import { paths } from "../paths"

const AccountVerificationRoute = () => {
  const classes = useStyles()
  const translations = useTranslations(defaultTranslations)
  const { user } = useAuthUser()
  const [error, setError] = React.useState<null | string>(null)
  const [redirectTo, setRedirectTo] = React.useState<null | string>(null)
  const location = useLocation()

  const formikProps = {
    initialValues: { code: "" },
    onSubmit: async ({ code }: { code: string }) => {
      if (user) {
        const [apiError] = await doublet(userApi.verifyAccount, { user: user.id, code })
        if (apiError) return setError(apiError.message)
        setRedirectTo(paths.userWelcome())
      }
    },
  }

  if (redirectTo) {
    return <Redirect to={{ pathname: redirectTo, state: { from: location } }} />
  }

  return (
    <Paper elevation={0} className={classes.container}>
      <Typography variant="h5" className={classes.title} data-test="welcome-header">
        {translations.pageTitle}
        {user?.firstname}
      </Typography>
      <Typography variant="body1" component="p" color="textSecondary" className={classes.subtitle}>
        {translations.pageSubtitle}
        <strong>{user?.email}</strong>
      </Typography>
      <Formik {...formikProps}>
        {({ errors, values, isSubmitting }) => {
          const shouldDisableButton = Boolean(values.code === "" || errors.code || isSubmitting)
          return (
            <Form>
              <FormLabel className={classes.formLabel}>{translations.inputLabel}</FormLabel>
              <FormikInput
                id="code"
                name="code"
                variant="outlined"
                placeholder="e.g 123-456"
                disabled={isSubmitting}
                autoComplete="off"
                className={classes.formInput}
                fullWidth
              />
              {error && (
                <div className={classes.formError}>
                  <Alert severity={"error"}>
                    <AlertTitle>{translations.errorTitle}</AlertTitle>
                    {error ? error : translations.verificationFailedMsg}
                  </Alert>
                </div>
              )}
              <div className={classes.formActions}>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  startIcon={isSubmitting ? <CircularProgress size={16} color="secondary" /> : null}
                  disabled={shouldDisableButton}
                >
                  {translations.verifyAccountBtnLabel}
                </Button>
                <Button color="primary" component={Link} to={"/new-verification-code"}>
                  {translations.noCodeBtnLabel}
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </Paper>
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n("translation")
  const accountVerificationPageTranslations = (t?.accountVerificationPage || {}) as StringMap
  const {
    inputLabel = defaults.inputLabel,
    errorTitle = defaults.errorTitle,
    pageTitle = defaults.pageTitle,
    pageSubtitle = defaults.pageSubtitle,
    verificationFailedMsg = defaults.verificationFailedMsg,
    verifyAccountBtnLabel = defaults.verifyAccountBtnLabel,
    noCodeBtnLabel = defaults.noCodeBtnLabel,
  } = accountVerificationPageTranslations

  return {
    inputLabel,
    errorTitle,
    pageTitle,
    pageSubtitle,
    verificationFailedMsg,
    verifyAccountBtnLabel,
    noCodeBtnLabel,
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: 650,
    border: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(2),
    padding: theme.spacing(3, 4),
  },
  title: {
    paddingBottom: theme.spacing(1.5),
  },
  subtitle: {
    paddingBottom: theme.spacing(4),
    "& strong": {
      textDecoration: "underline",
      color: theme.palette.common.black,
    },
  },
  formLabel: {
    display: "block",
    marginBottom: theme.spacing(1.5),
  },
  formInput: { marginBottom: theme.spacing(4) },
  formError: { marginBottom: theme.spacing(4) },
  formActions: {
    display: "flex",
    "& > :first-child": {
      marginRight: theme.spacing(2),
    },
  },
}))

const defaultTranslations = {
  inputLabel: "Verification code",
  errorTitle: "Verification failed",
  pageTitle: "Welcome ",
  pageSubtitle: "We have sent a verification code to your email ",
  verificationFailedMsg: "Failed to verify account",
  verifyAccountBtnLabel: "Verify account",
  noCodeBtnLabel: "I don't have code",
}
type Translations = typeof defaultTranslations

export default AccountVerificationRoute
