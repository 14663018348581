import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import FormLabel from "@material-ui/core/FormLabel"
import FormikInput from "../../components/FormikInput"
import ErrorFallback from "../../components/ErrorFallback"
import Checkbox from "@material-ui/core/Checkbox"
import Stack from "../../components/Stack"

import { makeStyles, Theme, Typography } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { Formik, Form, FormikHelpers } from "formik"
import { useErrorHandler } from "react-error-boundary"
import { useI18n, useNetworkStatus, useRouter } from "../../hooks"
import { paths } from "../../paths"
import { CreateOrganisation } from "../../organisations/store/actions"
import { ErrorBoundary } from "react-error-boundary"
import { DateTimeService } from "../../services/date-time-service"
import { RefreshAccessToken } from "../../users/store/actions"
import { useUrlWithContext } from "../../hooks/use-url-with-context"
import { FetchContextOptions } from "../../context-options/store/actions"
import { FetchUserMemberships } from "../../memberships/store"
import { FetchMembershipPermissions } from "../../permissions/store/actions"
import { useAppDispatch } from "../../store"

const NewOrganisationRoute = () => {
  const classes = useStyles()
  const translations = useTranslations()

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Paper elevation={0} className={classes.container} data-test="new-org-card">
        <header>
          <Typography variant="h4">{translations.createOrganisationHeader}</Typography>
          <Typography color="textSecondary">{translations.createOrganisationHelperText}</Typography>
        </header>
        <OrganisationForm />
      </Paper>
    </ErrorBoundary>
  )
}

const OrganisationForm = () => {
  const classes = useStyles()
  const translations = useTranslations()
  const dispatch = useAppDispatch()
  const { createPathWithGivenContext } = useUrlWithContext()
  const handleError = useErrorHandler()
  const router = useRouter()
  const networkStatus = useNetworkStatus({ resetDelayInMS: 3000 })
  const { setStatus, isRejected, isIdle } = networkStatus

  const handleCreateOrganisation = async (values: FormikState, { setSubmitting }: FormikHelpers<FormikState>) => {
    const dateTimeService = new DateTimeService()
    const timeZone = dateTimeService.getOsTimeZone()

    try {
      setStatus("pending")
      const actionResult: any = await dispatch(CreateOrganisation({ name: values.name, timeZone }))
      const requestStatus = actionResult.meta.requestStatus
      if (requestStatus === "fulfilled") {
        const orgId = actionResult.payload.id
        const refreshTokenPromise = dispatch(RefreshAccessToken())
        const contextOptionsPromise = dispatch(FetchContextOptions())
        const userMembershipsPromise = dispatch(FetchUserMemberships())
        const resolved = await Promise.all([refreshTokenPromise, contextOptionsPromise, userMembershipsPromise])

        const userMembershipsRequest = resolved[2] as any
        if (userMembershipsRequest.meta.requestStatus === "fulfilled") {
          const userMembership = userMembershipsRequest.payload.find((membership: any) => membership.orgId === orgId)
          if (userMembership) {
            dispatch(FetchMembershipPermissions(userMembership.id))
          }
        }

        const path = createPathWithGivenContext({ path: paths.orgWelcome(), mainContextId: orgId })
        router.push(path)
      }
      setStatus(requestStatus)
      setSubmitting(false)
    } catch (error: any) {
      handleError(error)
    }
  }

  const formikProps = {
    initialValues: { name: "", tosAccepted: false },
    onSubmit: handleCreateOrganisation,
  }

  return (
    <Formik {...formikProps}>
      {({ errors, values, handleChange }) => {
        const shouldDisableInput = !isIdle()
        const shouldDisableButton = Boolean(values.name === "" || errors.name || !values.tosAccepted || !isIdle())

        let errorMessage
        if (isRejected()) errorMessage = translations.createOrganisationFailed

        return (
          <Form>
            <Stack>
              <div>
                <FormLabel htmlFor="name">{translations.orgName}</FormLabel>
                <FormikInput
                  id="name"
                  name="name"
                  variant="outlined"
                  placeholder="e.g. StarBrix Intl"
                  disabled={shouldDisableInput}
                  autoComplete="off"
                  fullWidth
                  data-test="organisation-name-input"
                />
              </div>
              <p>
                <Checkbox
                  name="tosAccepted"
                  color="primary"
                  style={{ marginLeft: -12 }}
                  checked={values.tosAccepted}
                  onChange={(e) => handleChange(e)}
                  data-test="accept-agreement-checkbox"
                />
                {translations.acceptCustomerAgreement}&nbsp;
                <a href="https://www.starbrix.app/legal/terms-of-service" target="_blank" rel="noreferrer">
                  {translations.termsAndConditions}
                </a>
                &nbsp;&&nbsp;
                <a href="https://www.starbrix.app/legal/privacy-policy" target="_blank" rel="noreferrer">
                  {translations.privacyPolicy}
                </a>
                &nbsp;{translations.hasAuthority}
              </p>
              {errorMessage && (
                <div className={classes.alert}>
                  <Alert severity="error">{errorMessage}</Alert>
                </div>
              )}
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={shouldDisableButton}
                  data-test="create-organisation-button"
                >
                  {translations.createOrganisation}
                </Button>
              </div>
            </Stack>
          </Form>
        )
      }}
    </Formik>
  )
}

const defaultTranslations = {
  createOrganisationHeader: "Create a new organisation",
  createOrganisationHelperText:
    "By creating an organisation you can use StarBrix to collaborate and communicate with team members for project management.",
  orgName: "Organisation name",
  acceptCustomerAgreement: "I hereby accept that I have read and agree to",
  termsAndConditions: "Terms of Service",
  privacyPolicy: "Privacy Policy",
  hasAuthority: "on behalf of the organisation and I have the authority to do so.",
  createOrganisation: "Create Organisation",
  createOrganisationFailed: "Failed to create organisation",
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n("translation")
  const translations = t || ({} as Translations)

  const translate = (key: keyof Translations) => {
    return translations[key] || defaults[key]
  }

  return {
    createOrganisationHeader: translate("createOrganisationHeader"),
    createOrganisationHelperText: translate("createOrganisationHelperText"),
    orgName: translate("orgName"),
    acceptCustomerAgreement: translate("acceptCustomerAgreement"),
    termsAndConditions: translate("termsAndConditions"),
    privacyPolicy: translate("privacyPolicy"),
    hasAuthority: translate("hasAuthority"),
    createOrganisation: translate("createOrganisation"),
    createOrganisationFailed: translate("createOrganisationFailed"),
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: 650,
    margin: theme.spacing(4, "auto"),
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.divider}`,
    "& header": {
      "& h4": { marginBottom: theme.spacing(3) },
      "& p": { marginBottom: theme.spacing(6) },
    },
  },
  alert: { marginBottom: theme.spacing(2) },
}))

export default NewOrganisationRoute

type FormikState = { name: string; tosAccepted: boolean }
type Translations = typeof defaultTranslations
