import { useSnackbar } from "notistack"
import * as taskApi from "../api"
import { TaskViewModel } from "../api/task"

export const useTaskMutations = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [createUserTaskMutation] = taskApi.useCreateUserTaskMutation()
  const [createOrgTaskMutation] = taskApi.useCreateOrgTaskMutation()
  const [copyTaskMutation] = taskApi.useCopyTaskMutation()
  const [updateTaskInfoMutation] = taskApi.useUpdateTaskInfoMutation()
  const [updateTaskStatusMutation] = taskApi.useUpdateTaskStatusMutation()
  const [updateStatusDescriptionMutation] = taskApi.useUpdateTaskStatusDescriptionMutation()
  const [updateTaskPlanMutation] = taskApi.useUpdateTaskPlanMutation()
  const [updateTaskDurationMutation] = taskApi.useUpdateTaskDurationMutation()
  const [updateTaskDaysLeftMutation] = taskApi.useUpdateTaskDaysLeftMutation()
  const [updateTaskPermissionsMutation] = taskApi.useUpdateTaskPermissionsMutation()
  const [updateTaskOrderMutation] = taskApi.useUpdateTaskOrderMutation()
  const [addTaskManagersMutation] = taskApi.useAddTaskManagersMutation()
  const [removeTaskManagersMutation] = taskApi.useRemoveTaskManagersMutation()
  const [addTaskParticipantsMutation] = taskApi.useAddTaskParticipantsMutation()
  const [removeTaskParticipantsMutation] = taskApi.useRemoveTaskParticipantsMutation()
  const [addTaskSuppliersMutation] = taskApi.useAddTaskSuppliersMutation()
  const [removeTaskSuppliersMutation] = taskApi.useRemoveTaskSuppliersMutation()
  const [addTaskWorkspacesMutation] = taskApi.useAddTaskWorkspacesMutation()
  const [removeTaskWorkspacesMutation] = taskApi.useRemoveTaskWorkspacesMutation()
  const [addTaskDependencyMutation] = taskApi.useAddTaskDependencyMutation()
  const [removeTaskDependencyMutation] = taskApi.useRemoveTaskDependencyMutation()
  const [updateTaskDependencyMutation] = taskApi.useUpdateTaskDependencyMutation()
  const [archiveTaskMutation] = taskApi.useArchiveTaskMutation()
  const [unarchiveTaskMutation] = taskApi.useUnarchiveTaskMutation()
  const [deleteTaskMutation] = taskApi.useDeleteTaskMutation()
  const [bulkCreateTasksMutation] = taskApi.useBulkCreateTasksMutation()
  const [updateTaskCompletionPercentageMutation] = taskApi.useUpdateTaskCompletionPercentageMutation()

  const handleError = (error: any) => {
    const errorMessage = "error" in error ? error.error : "message" in error ? error.message : "Unknown error"
    enqueueSnackbar(errorMessage, { variant: "error" })
  }

  const createUserTask = async (userId: string, taskData: taskApi.NewTaskData) => {
    return await createUserTaskMutation({ userId, ...taskData })
      .unwrap()
      .catch(handleError)
  }

  const createOrgTask = async (orgId: string, taskData: taskApi.NewTaskData) => {
    return await createOrgTaskMutation({ orgId, ...taskData })
      .unwrap()
      .catch(handleError)
  }

  const bulkCreateTasks = async (taskData: taskApi.NewBulkTaskData) => {
    return await bulkCreateTasksMutation(taskData).unwrap().catch(handleError)
  }

  const copyTask = async (taskId: string, taskData: Omit<taskApi.CopyTaskData, "taskId"> = {}) => {
    return await copyTaskMutation({ taskId, ...taskData })
      .unwrap()
      .catch(handleError)
  }

  const updateInfo = async (taskId: string, updateData: taskApi.TaskInfoUpdateData) => {
    return await updateTaskInfoMutation({ taskId, ...updateData })
      .unwrap()
      .catch(handleError)
  }

  const updateStatus = async (taskId: string, updateData: taskApi.TaskStatusUpdateData) => {
    return await updateTaskStatusMutation({ taskId, ...updateData })
      .unwrap()
      .catch(handleError)
  }

  const updateStatusDescription = async (taskId: string, updateData: taskApi.TaskStatusDescriptionUpdateData) => {
    return await updateStatusDescriptionMutation({ taskId, ...updateData })
      .unwrap()
      .catch(handleError)
  }

  const updatePlan = async (taskId: string, updateData: taskApi.TaskPlanUpdateData) => {
    return await updateTaskPlanMutation({ taskId, ...updateData })
      .unwrap()
      .catch(handleError)
  }

  const updateDuration = async (taskId: string, updateData: taskApi.TaskDurationUpdateData) => {
    return await updateTaskDurationMutation({ taskId, ...updateData })
      .unwrap()
      .catch(handleError)
  }

  const updateDaysLeft = async (taskId: string, updateData: taskApi.TaskDaysLeftUpdateData) => {
    return await updateTaskDaysLeftMutation({ taskId, ...updateData })
      .unwrap()
      .catch(handleError)
  }

  const updatePermissions = async (taskId: string, updateData: taskApi.TaskPermissionsUpdateData) => {
    return await updateTaskPermissionsMutation({ taskId, ...updateData })
      .unwrap()
      .catch(handleError)
  }

  const updateOrder = async (taskId: string, updateData: taskApi.TaskOrderUpdateData) => {
    return await updateTaskOrderMutation({ taskId, ...updateData })
      .unwrap()
      .catch(handleError)
  }

  const addManagers = async (taskId: string, managerIds: string[]) => {
    return await addTaskManagersMutation({ taskId, managerIds }).unwrap().catch(handleError)
  }

  const removeManagers = async (taskId: string, updateData: { managerIds: string[]; boardId?: string }) => {
    return await removeTaskManagersMutation({ taskId, ...updateData })
      .unwrap()
      .catch(handleError)
  }

  const addParticipants = async (taskId: string, participantIds: string[]) => {
    return await addTaskParticipantsMutation({ taskId, participantIds }).unwrap().catch(handleError)
  }

  const removeParticipants = async (taskId: string, updateData: { participantIds: string[]; boardId?: string }) => {
    return await removeTaskParticipantsMutation({ taskId, ...updateData })
      .unwrap()
      .catch(handleError)
  }

  const addSuppliers = async (taskId: string, supplierIds: string[]) => {
    return await addTaskSuppliersMutation({ taskId, supplierIds }).unwrap().catch(handleError)
  }

  const removeSuppliers = async (taskId: string, updateData: { supplierIds: string[]; boardId?: string }) => {
    return await removeTaskSuppliersMutation({ taskId, ...updateData })
      .unwrap()
      .catch(handleError)
  }

  const addWorkspaces = async (taskId: string, workspaceIds: string[]) => {
    return await addTaskWorkspacesMutation({ taskId, workspaceIds }).unwrap().catch(handleError)
  }

  const removeWorkspaces = async (taskId: string, updateData: { workspaceIds: string[]; boardId?: string }) => {
    return await removeTaskWorkspacesMutation({ taskId, ...updateData })
      .unwrap()
      .catch(handleError)
  }

  const addDependency = async (taskId: string, dependencyData: taskApi.AddTaskDependencyData) => {
    return await addTaskDependencyMutation({ taskId, ...dependencyData })
      .unwrap()
      .catch(handleError)
  }

  const removeDependency = async (taskId: string, dependencyData: taskApi.RemoveTaskDependencyData) => {
    return await removeTaskDependencyMutation({ taskId, ...dependencyData })
      .unwrap()
      .catch(handleError)
  }

  const updateDependency = async (taskId: string, updateData: taskApi.TaskDependencyUpdateData) => {
    return await updateTaskDependencyMutation({ taskId, ...updateData })
      .unwrap()
      .catch(handleError)
  }

  const archiveTask = async ({ taskId, boardId }: { taskId: string; boardId?: string }) => {
    return await archiveTaskMutation({ taskId, boardId }).unwrap().catch(handleError)
  }

  const unarchiveTask = async (taskId: string, options: { unarchiveSubtasks?: boolean; boardId?: string } = {}) => {
    return await unarchiveTaskMutation({ taskId, ...options })
      .unwrap()
      .catch(handleError)
  }

  const deleteTask = async (taskId: string) => {
    return await deleteTaskMutation(taskId).unwrap().catch(handleError)
  }

  const updateTaskCompletionPercentage = async (
    taskId: string,
    updateData: taskApi.TaskCompletionPercentageUpdateData
  ) => {
    return await updateTaskCompletionPercentageMutation({ taskId, ...updateData })
      .unwrap()
      .catch(handleError)
  }

  const updateManagers = async (
    taskId: string,
    { add, remove, boardId }: ResourceUpdateData & { boardId?: string }
  ): Promise<TaskViewModel | void> => {
    let updatedTask: TaskViewModel | void = undefined
    const shouldAdd = add.length > 0
    const shouldRemove = remove.length > 0
    const shouldDelay = shouldAdd && shouldRemove
    if (shouldAdd) updatedTask = await addManagers(taskId, add)
    if (shouldDelay) await delay(50)
    if (shouldRemove) {
      const removed = await removeManagers(taskId, { managerIds: remove, boardId })
      if (removed) updatedTask = removed.task
    }
    return updatedTask
  }

  const updateParticipants = async (
    taskId: string,
    { add, remove, boardId }: ResourceUpdateData & { boardId?: string }
  ): Promise<TaskViewModel | void> => {
    let updatedTask: TaskViewModel | void = undefined
    const shouldAdd = add.length > 0
    const shouldRemove = remove.length > 0
    const shouldDelay = shouldAdd && shouldRemove
    if (shouldAdd) {
      const task = await addParticipants(taskId, add)
      if (task) updatedTask = task
    }
    if (shouldDelay) await delay(50)
    if (shouldRemove) {
      const removeResult = await removeParticipants(taskId, { participantIds: remove, boardId })
      if (removeResult) updatedTask = removeResult.task
    }
    return updatedTask
  }

  const updateSuppliers = async (
    taskId: string,
    { add, remove, boardId }: ResourceUpdateData & { boardId?: string }
  ): Promise<TaskViewModel | void> => {
    let updatedTask: TaskViewModel | void = undefined
    const shouldAdd = add.length > 0
    const shouldRemove = remove.length > 0
    const shouldDelay = shouldAdd && shouldRemove
    if (shouldAdd) {
      const task = await addSuppliers(taskId, add)
      if (task) updatedTask = task
    }
    if (shouldDelay) await delay(50)
    if (shouldRemove) {
      const removeResult = await removeSuppliers(taskId, { supplierIds: remove, boardId })
      if (removeResult) updatedTask = removeResult.task
    }
    return updatedTask
  }

  const updateWorkspaces = async (
    taskId: string,
    { add, remove, boardId }: ResourceUpdateData & { boardId?: string }
  ): Promise<TaskViewModel | void> => {
    let updatedTask: TaskViewModel | void = undefined
    const shouldAdd = add.length > 0
    const shouldRemove = remove.length > 0
    const shouldDelay = shouldAdd && shouldRemove
    if (shouldAdd) {
      const task = await addWorkspaces(taskId, add)
      if (task) updatedTask = task
    }
    if (shouldDelay) await delay(50)
    if (shouldRemove) {
      const removeResult = await removeWorkspaces(taskId, { workspaceIds: remove, boardId })
      if (removeResult) updatedTask = removeResult.task
    }
    return updatedTask
  }

  const initTaskUpdate = async (taskId: string, updateData: InitTaskUpdateData) => {
    switch (updateData.field) {
      case "title":
      case "description":
      case "ganttBarColor":
      case "taskNumber":
        return await updateInfo(taskId, { [updateData.field]: updateData.value })
      case "statusDescription":
      case "state":
        return await updateStatusDescription(taskId, { [updateData.field]: updateData.value })
      case "plannedStartDate":
      case "plannedEndDate":
        return await updatePlan(taskId, { [updateData.field]: updateData.value })
      case "duration":
        return await updateDuration(taskId, { [updateData.field]: updateData.value })
      case "daysLeft":
        return await updateDaysLeft(taskId, { [updateData.field]: updateData.value })
      case "managers":
        return await updateManagers(taskId, { ...updateData.value, boardId: updateData.boardId })
      case "participants":
        return await updateParticipants(taskId, { ...updateData.value, boardId: updateData.boardId })
      case "suppliers":
        return await updateSuppliers(taskId, { ...updateData.value, boardId: updateData.boardId })
      case "workspaces":
        return await updateWorkspaces(taskId, { ...updateData.value, boardId: updateData.boardId })
      case "completionPercentage":
        return await updateTaskCompletionPercentage(taskId, { completionPercentage: updateData.value })
      default:
        // @ts-expect-error
        const error = `Field ${updateData.field} can not be updated via initTaskUpdate`
        // eslint-disable-next-line no-console
        console.error(error)
        return
    }
  }

  return {
    createUserTask,
    createOrgTask,
    bulkCreateTasks,
    copyTask,
    updateInfo,
    updateStatus,
    updateStatusDescription,
    updatePlan,
    updateDuration,
    updateDaysLeft,
    updatePermissions,
    updateOrder,
    addManagers,
    removeManagers,
    addParticipants,
    removeParticipants,
    addSuppliers,
    removeSuppliers,
    addWorkspaces,
    removeWorkspaces,
    addDependency,
    removeDependency,
    updateDependency,
    archiveTask,
    unarchiveTask,
    deleteTask,
    updateManagers,
    updateParticipants,
    updateSuppliers,
    updateWorkspaces,
    updateTaskCompletionPercentage,
    initTaskUpdate,
  }
}

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

type ResourceUpdateData = {
  add: string[]
  remove: string[]
}

type InitTaskUpdateData =
  | {
      field:
        | "title"
        | "description"
        | "taskNumber"
        | "statusDescription"
        | "state"
        | "plannedStartDate"
        | "plannedEndDate"
        | "ganttBarColor"
      value: string
    }
  | {
      field: "managers" | "participants" | "suppliers" | "workspaces"
      value: ResourceUpdateData
      boardId?: string
    }
  | {
      field: "duration"
      value: number
    }
  | {
      field: "daysLeft"
      value: number
    }
  | {
      field: "completionPercentage"
      value: number
    }
